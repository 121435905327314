import { Outlet } from '@remix-run/react'
import { atom } from 'jotai'


export function Logo(props: { className?: string }) {
  return <div className="text-xl p-2 text-gray-600">LOGO</div>
}

export const sidebarOpenAtom = atom(false)


export function Layout(props: React.PropsWithChildren<{}>) {
  return (
    <div className="flex min-h-screen flex-col">
      <Outlet />
    </div>
  )
}
